import React, { useEffect, useState } from "react";
// import Main from "../components/home/Main";
import Partner from "../components/partner/core/Partner";
import NerdwalletPartnership from "../components/nerdwallet/NerdwalletPartnership";
import {
  NERDWALLET_MARKETING_HOST_NAME,
  NERDWALLET_SMB_MARKETING_HOST_NAME,
} from "../utils/commonConst";
import { getHostName, isEmpty } from "../utils/commonUtil";
import LoadingGif from "../components/common/LoadingGif";
import NerdwalletSMBPartnership from "../components/nerdwallet-smb/NerdwalletPartnership";

export default function Home(props) {
  const [hostName, setHostName] = useState("");
  useEffect(() => {
    setHostName(getHostName());
  }, []);

  if (isEmpty(hostName)) {
    return <LoadingGif />;
  }
  switch (hostName) {
    case NERDWALLET_MARKETING_HOST_NAME:
      return <NerdwalletPartnership />;
    case NERDWALLET_SMB_MARKETING_HOST_NAME:
      return <NerdwalletSMBPartnership />;
    default:
      return <Partner />;
  }
}
