/* eslint-disable no-underscore-dangle */
import React from "react";
import styled, { useTheme } from "styled-components";

import HeaderText from "./HeaderText";
import { HEADER_LEVEL } from "../../utils/commonConst";
import { Colors } from "../../styles/styleConstants";
import Image from "./Image";

export const LOADING_GIF_SIZE = {
  SMALL: "small",
  LARGE: "large",
};

function LoadingGif(props: { size?: string }) {
  const { size = LOADING_GIF_SIZE.LARGE } = props ?? {};
  const theme = useTheme();

  return (
    <LoadingGifWrapper size={size}>
      <LoadingContent>
        <Image
          src="common/loading.gif"
          style={{
            width: "24px",
            height: "24px",
            display: "inline-block",
          }}
        />
        <HeaderText
          level={HEADER_LEVEL.LEVEL_4}
          style={{ color: Colors.white }}
        >
          Loading...
        </HeaderText>
      </LoadingContent>
    </LoadingGifWrapper>
  );
}

export default LoadingGif;

const LoadingGifWrapper = styled.div<{ size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${Colors.white};
}
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingImage = styled.img`
  width: 50%;
  margin-bottom: 24px;
`;
