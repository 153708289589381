import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import HeaderText from "../../common/HeaderText";
import Image from "../../common/Image";
import TealBg from "../../../images/partner/img-tealBG@2x.png";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";

function FlexibleIntegration() {
  return (
    <IntegrationWrapper>
      <Container className="flexible-integration-container">
        <HeaderText level={HEADER_LEVEL.LEVEL_1}>
          Flexible, seamless integration.
        </HeaderText>
        <Layout>
          <Col md={6} lg={6}>
            <BoxWrapper>
              <HeaderText
                style={{ margin: `0 0 ${Spacing.lg} 0` }}
                level={HEADER_LEVEL.LEVEL_4}
              >
                Launch a white-labeled solution in days.
              </HeaderText>
              <Image
                src="partner/img-whitelabeled@2x.png"
                style={{ width: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              />
            </BoxWrapper>
          </Col>
          <Col md={6} lg={6}>
            <BoxWrapper>
              <HeaderText
                style={{ margin: `0 0 ${Spacing.lg} 0` }}
                level={HEADER_LEVEL.LEVEL_4}
              >
                Or use our APIs to customize.
              </HeaderText>
              <Image
                src="partner/img-APIs@2x.png"
                style={{ width: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              />
            </BoxWrapper>
          </Col>
        </Layout>
        <BottomBoxWrapper>
          <ImageGroup>
            <Image
              src="partner/img-tealCard@2x.png"
              style={{ width: "100%" }}
            />
          </ImageGroup>
          <ImageText>
            <HeaderText level={HEADER_LEVEL.LEVEL_4}>
              We cover the security and regulatory overhead, always.
            </HeaderText>
          </ImageText>
        </BottomBoxWrapper>
      </Container>
    </IntegrationWrapper>
  );
}

export default FlexibleIntegration;

const IntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.sm}) {
    .flexible-integration-container {
      padding: 0px ${Spacing.md} ${Spacing.md} !important;
    }
  }
`;

const Layout = styled(Row)`
  margin-top: ${Spacing.xxxl};
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: ${Spacing.lg};
  }
`;

const BoxWrapper = styled.div`
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: ${Spacing.xl} 0 0 ${Spacing.xl};
  border-radius: ${Spacing.xs};
  width: 100%;

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.lg} 0 0 ${Spacing.lg};
  }
`;

const BottomBoxWrapper = styled.div`
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${Spacing.lg};
  border-radius: ${Spacing.xs};
  gap: ${Spacing.xs};

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    padding-bottom: ${Spacing.lg};
    gap: ${Spacing.lg};
  }
`;

const ImageGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.md};
  z-index: 1;
  overflow: hidden;
  border-radius: ${Spacing.xs};

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    padding-right: ${Spacing.xs};
    border-bottom-left-radius: 0;
  }
`;

const ImageText = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 0 ${Spacing.lg};

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    padding: 0 ${Spacing.md};
  }
`;
