import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Image from "../../common/Image";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  Spacing,
} from "../../../styles/styleConstants";
import HeaderText from "../../common/HeaderText";

function UserReview() {
  return (
    <ReviewWrapper>
      <Container className="user-review-container">
        <Row className="review-row">
          <Col md={12} lg={4}>
            <Image
              src="partner/img-mary@2x.png"
              style={{ width: "180px", margin: "auto" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Col>
          <Col md={12} lg={8} style={{ padding: 0 }}>
            <CustomText
              style={{ margin: `0 0 ${Spacing.lg} 0` }}
              level={HEADER_LEVEL.LEVEL_4_1}
            >
              “Uprise’s AI technology is <b>game-changing</b> in enabling new
              communities to build wealth. And they’ve been able to match how
              fast we move, allowing us to{" "}
              <b>get a product out to customers in only a few weeks!</b>”
            </CustomText>
            <HeaderText level={HEADER_LEVEL.LEVEL_4_1}>
              - Mary, Suma Wealth
            </HeaderText>
          </Col>
        </Row>
      </Container>
    </ReviewWrapper>
  );
}

export default UserReview;

const ReviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  padding: 104px 0 ${Spacing.xxxxxl};

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.xxxxxl} ${Spacing.md} 64px;
    width: 100%;

    .user-review-container {
      padding: 0px ${Spacing.md} ${Spacing.md} !important;
    }

    .review-row {
      gap: ${Spacing.lg};
    }
  }
`;

const CustomText = styled.div`
  font-family: ${FontFamilies.base};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: ${Colors.almostBlack};
  padding: 0 !important;
`;
