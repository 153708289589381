import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints, Spacing } from "../../../styles/styleConstants";
import HeaderText from "../../common/HeaderText";
import { composeReqParam, navigateTo } from "../../../utils/commonUtil";
import CtaButton from "../../common/CtaButton";

import {
  APP_QUERY_PARAMS,
  HEADER_LEVEL,
  CALENDARLY_LINK,
  PAGE_NAME,
} from "../../../utils/commonConst";
import Image from "../../common/Image";

export default function PartnerHero() {
  return (
    <HeroWrapper>
      <Container className="partner-hero-container">
        <Col className="text-container">
          <HeaderText level={HEADER_LEVEL.LEVEL_1}>
            Premium, AI-powered financial advisory{" "}
            <TextHighlight>for&nbsp;your&nbsp;platform.</TextHighlight>
          </HeaderText>
          <HeaderText
            style={{ margin: `${Spacing.lg} 0` }}
            level={HEADER_LEVEL.LEVEL_4_1}
          >
            We built the first truly personalized financial optimization engine,
            backed by our team of certified financial advisors, so you don't
            have to.
          </HeaderText>
          <CtaWrapper>
            <CtaButton
              onClick={(evt) => {
                navigateTo(
                  CALENDARLY_LINK,
                  composeReqParam(APP_QUERY_PARAMS.SOURCE, PAGE_NAME.PARTNER)
                );
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
            >
              Schedule a call
            </CtaButton>
            {/* <CtaButton
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="secondary"
              >
                View API docs
              </CtaButton> */}
          </CtaWrapper>
        </Col>
      </Container>
      <Col className="pr-0">
        <Image className="HeroImage" src="partner/img-header-all@2x.png" />
      </Col>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  width: 100% !important;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  .HeroImage {
    width: 100%;
  }

  .partner-hero-container {
    width: 50%;
    padding-right: 0 !important;
  }

  .text-container {
    padding-left: 15vw !important;
    @media (max-width: 1620px) {
      padding-left: 13vw !important;
    }
    @media (max-width: 1440px) {
      padding-left: 10vw !important;
    }
    @media (max-width: ${Breakpoints.lg}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.md}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.sm}) {
      margin-right: ${Spacing.md} !important;
      padding-left: ${Spacing.md} !important;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 24px 0px;
    flex-direction: column;

    .partner-hero-container {
      width: 100%;
      padding: 0 !important;
    }
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  margin: ${Spacing.lg} 0;
  gap: ${Spacing.lg};

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TextHighlight = styled.span`
  position: relative;
  min-height: 65px;
  z-index: 1;
  margin-bottom: ${Spacing.sm};
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${Spacing.xs};
    z-index: -1;
    background: linear-gradient(29.68deg, #3c5eff 0%, #00f4de 84.39%);
  }

  @media (max-width: 768px) {
    ::after {
      bottom: -4px;
    }
  }
`;
