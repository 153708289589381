import React from "react";
import Layout from "../../layouts/layout";
import Header from "../../common/header";
import styled from "styled-components";
import {
  BackedBy,
  PressReleaseData,
  FinancialInstitution,
  WhatYouGetAsPartnerContent,
} from "../../../data/homeData";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import CurveTopBg from "../../../images/home/gray-curve-top-bg.png";
import { CALENDARLY_LINK, QUERY_PARAMS } from "../../../utils/commonConst";
import { isNonEmpty, parseReqParams } from "../../../utils/commonUtil";
import PartnerHero from "./PartnerHero";
import FinancialLive from "./FinancialLive";
import FlexibleIntegration from "./FlexibleIntegration";
import UserReview from "./UserReview";
import WhatYouGetAsPartner from "./WhatYouGetAsPartner";
import AIHumanSecretV2 from "./AIHumanSecretV2";
import LogoBarV2 from "./logoBarV2";
import PartnerContactUsV2 from "./PartnerContactUsV2";
import FooterV2 from "./footerV2";
import Footer from "../../common/footer";

export default function Partner(props) {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;
  return (
    <Layout includeHeader={false} includeFooter={false}>
      <HeroWrapper>
        <Header />
        <PartnerHero signupCode={signupCode} />
      </HeroWrapper>
      <LogoBarV2
        content={FinancialInstitution}
        signupCode={signupCode}
        style={{ padding: "32px 0px 0px" }}
      />
      <WhatYouGetAsPartner
        whatYouGetAsPartnerData={WhatYouGetAsPartnerContent}
      />
      <LogoBarV2
        content={PressReleaseData}
        signupCode={signupCode}
        style={{ padding: "48px 0px 40px" }}
      />
      <FinancialLivesWrapper>
        <FinancialLive />
      </FinancialLivesWrapper>
      <IntegrationWrapper>
        <FlexibleIntegration />
      </IntegrationWrapper>
      <BackedBySectionWrapper>
        <LogoBarV2 content={BackedBy} signupCode={signupCode} />
      </BackedBySectionWrapper>
      <AIHumanSecretV2 />
      <UserReviewWrapper>
        <UserReview />
      </UserReviewWrapper>
      <PartnerContactUsV2
        headerText={"Let’s work together 🤍"}
        buttonName={"Schedule a call"}
        buttonLinkTarget={CALENDARLY_LINK}
      />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const HeroWrapper = styled.div`
  margin: 0 0 ${Spacing.xxxl};
  .HeroImage {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 0 0 ${Spacing.xl};
  }
`;

const FinancialLivesWrapper = styled.div`
  background-color: #ebefff;
  margin-top: ${Spacing.xl};
  padding: 96px 0 ${Spacing.xxxxl};

  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 0;
    padding: 24px 0 0;
  }
`;

const IntegrationWrapper = styled.div`
  padding: 96px 0 0;
  @media (max-width: ${Breakpoints.sm}) {
    padding: 64px 0 0;
  }
`;

const BackedBySectionWrapper = styled.div`
  margin: ${Spacing.xxl} 0 0;
`;

const UserReviewWrapper = styled.div`
  border-top: 2px solid ${Colors.neutralMedium};
  border-bottom: 2px solid ${Colors.neutralMedium};
  margin: ${Spacing.xxl} 0 ${Spacing.xxxxl};
  @media (max-width: ${Breakpoints.sm}) {
    margin: ${Spacing.xxxl} 0 0;
  }
`;
